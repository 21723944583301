import React from  'react'; 
import {SiteHeader, SiteFooter} from '../parts/index'; 
import sample from '../assets/assetsample.jpg';
export default function Foodvilix(){
    return(<main> 
      <div className="header">
            <SiteHeader />
      </div>   
    
      <div className="container-fluid"> 
        <div className="row"> 
          <div className="col-4 col-md-4 col-sm-4 col-xs-4">
          <h1 className="authorized-portal"><b>Navigate to Foodvilix Platform</b></h1>      
          </div>
          <div className="col-4 col-md-4 col-sm-4 col-xs-4">
             
          </div>
          <div className="col-4 col-md-4 col-sm-4">
              <h1 className="authorized-edition"><b>Buy Foodvilix Edition Book</b></h1>
          </div>
      </div>
      <div className="row"> 
        <div className="col-6 col-md-6 col-sm-6 col-xs-6"> 
        <img src={sample} alt=" " class="img-thumbnail" />
        </div>
        <div className="col-6 col-md-6 col-sm-6 col-xs-6">

        </div>
      </div>
      </div>
      <footer>
           <SiteFooter />
      </footer>
    </main>); 
}