import React from  'react'; 
import {SiteHeader, SiteFooter} from '../parts/index'; 


export default function Extend(){
    return(<main>
         <div className="header">
            <SiteHeader />
         </div> 
           <h1> Extend Cloud Platforms </h1>
        <footer>
        <SiteFooter />
        </footer>
        
        </main>); 
}