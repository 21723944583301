import '../index.css';import {SiteHeader, SiteFooter} from '../parts/index'; export default function EA_Rights(){ return (




<eacontext className="container-fluid"> <SiteHeader /> <container className="container-fluid">
 
<div className="policy">
      <p>EA Rights are promoter rights to manage and maintain integrity under terms &amp; conditions of Nextvilix Nextwares Private Limited recognised by EA, under protocol.</p>
      
    <ul align="justify"> 
       <li><p align="justify"><b>Policy Of IS:</b>  EA provision/role is privileged to manipulate analytical information for user experience & scope of the concerns in company policy, monitor maintenance, specificity, use of the content, and use of confidentiality. <b>Policy Of EE:</b> EA provision/role is privileged to use the evaluation of output submitted to matrices of Information retained in the system and execution of the software analysis in relevant Software Engineering practices as per policy. </p> </li>
       <li><p align="justify"><b>Policy Of IR:</b> EA provision/role is privileged to major in research in software maintenance, features, algorithms, and commercial digital commerce of the company's product administration. Software Engineers use this analysis to generate and manage scalability from products/services. <b>Policy Of PL:</b> EA provision/role is privileged to manage guidelines for the company's standard Information Security Protocols, builds, infrastructure, and commercial software Licences. In distinct ways to use the technology and solutions.</p></li>
       <li><p align="justify"><b>Policy Of SL:</b> EA provision/role is privileged to manage Software Licenses, Software Releases, and Commencement of exceptional work models of Software Engineering. User-understanding models built learning procedures again smartly. <b>Policy Of QA:</b> EA provision/role is privileged to write software testing & Quality Assurance Models, and quality techniques to write guidelines on software maintenance as per products rules privileged to be the propositions fall in the term 'software maintainance'.</p></li>
       <li><p align="justify"><b>Policy of PR:</b> EA provision/role is privileged to monitor product rules enforcing system security by use of software or nextware in the context and development in the systems builds. The software can be a key process, or software can be practiced. <b>Policy Of CL:</b> EA provision/role is privileged to license content on the company's copyrights policy relevant to technology. In the design of work goals work models build for practices. Copyrights are used by the company and also managed.</p></li> 
       <li><p align="justify"><b>Policy Of CV:</b> EA provision/role is privileged to respond to violation act in the use of registrant property provision/role by draft notice of violation letters or in case of violation of any investigations are compiled or responses need drafting out to concern.<b>Policy Of IT:</b> EA provision/role is privileged to publish guidelines for the use of the information technology for certain commercial edge to implement/publish new technology concerns policies. They belong to IP rights for research and publications.</p></li>
       <li><p align="justify"><b>Policy Of AI:</b> EA provision/role is privileged to practice major scope of thinking with algorithms in context to use as (Artificial Intelligence Guidelines) & (Interpretation Systems Guidelines) or (Commercial Intelligence Guidelines). <b>Policy of SE:</b> EA provision/role is privileged to constitute software execution & software practices in commencement to publish & monitor new context to driven development. Development of in-depth theory to use licenses and purposes. <b>Policy of PR:</b> EA provision/role is privileged to monitor the company's digital infrastructures, and cloud computing solutions for new integrated architecture with propositional systems like Extend Systems and platforms.</p></li>
    </ul>
</div> 
</container>
<SiteFooter />
</eacontext> ); }