import React from  'react'; 
import {SiteHeader, SiteFooter} from '../parts/index'; 
export default function Softvilix(){
    return(<main> 
        <div className="header">
            <SiteHeader />
         </div> 
        <h1> Softvilix Editions Platform </h1>
        <footer>
          <SiteFooter />
        </footer>
    </main>); 
}