import '../index.css';import {SiteHeader, SiteFooter} from '../parts/index'; 
export default function Commencements(){ 
  return( <terminology><container className="container-fluid">
<SiteHeader />
<div className="policy">
  <p><b>Deliveryship </b> is a Nextvilix Nextwares Private Limited task in sales process. Candidate discipline to work as per the policy, wordings. Commercial form of terms and services are limited to the job wording agreed on confirmation and appointment of the candidates.</p><p>It involves candidate registration for Deliveryship in role to deliver orders monitored by forming community of chefs, developers, writers, brand engineers, marketers working on a sales process.</p><p>All of the wordings retain its meaning from a word given to confidential entity, personality or former deliveryship candidate about job and identification principles. Wordings are mandatory and they form unique constitution in discipline to whom it is important to the company and policy.</p>
</div>
<bottom id="bottom-header"/>
</container>
<SiteFooter />
</terminology> ); }