import React from 'react'; import '../queries/style.css';
import {SiteHeader, SiteFooter} from '../parts/index';
import header7 from '../assets/header7.jpg'; 
import nextwaresimg from '../assets/nextwarers.jpg'; 
import image2 from '../assets/newposter2.jpg'; 
import header6 from '../assets/header6.jpg'; 
import header8 from '../assets/header8.jpg';

  export default function programmerthon(){
    return(
<programmerthon className="">
  <div class="header">
   <SiteHeader />
    </div>
    <main className="container-fluid container-fluid-strom">
        <main className="row">
            <span className="col-4 col-md-4 col-sm-6 col-xs-6">
              <img src={header7} id="view-id-container-ve" />
            </span>
           <span className="col-6 col-md-6 col-sm-4 col-xs-4">
              <h1 align="justify" class="display-4" id="Programmerthoncontent">
                <b>New <br /> Programerthon <br /> Will <br /> Be <br /> Held <br /> In <br />2025 </b></h1>
            </span>
            <span className="col-sm-2 col-xs-2"></span>
          </main>
          <main className="programmerthonup">
             <p align="justify" className="programmerthon"> 
               <strong>Programerthon starts in march 2025. It is held every 31st day of March each year. The event is hosted by Softvilix Nextwares Pvt Ltd Talent Curriculum. The programmerthon is the contest for programmers across Maharasshtra held on occesion of the company's recent launches or enhancement about technology programs monitored and hosted by the company each year. The Company promote curriculum and does the biggest contest on challenges of the softwares and systems. The contest informs programmers to know this event through the company website, prepare Data Science algorithms and Book online event ticket, get the programmerthonion key login event maskhead, write code in the editor and submit as per the given two challenges. Create a release. Win? Become the Programerthon Champion. We claim winners rights in front of our tech aspirants and visitors. We file/claim the winner's certify Softvilix Talent Curriculum certification to be trainee for a month or recrute. </strong></p> 
            </main>
        <main className='row'>
         <div className="col-4 col-md-4 col-sm-6 col-xs-6"  >
               <img src={header6}  id="view-id-container-ve"  />
          </div>
        </main>
        <span className="col-4 col-md-4 col-sm-12 col-xs-12">
              <img src={header8} id="view-id-container-ve" /> 
           </span>
    </main>
 <br />
 <br />
 <br />

 <footer>
 <SiteFooter /> 
 </footer>
</programmerthon>
   
   ); }