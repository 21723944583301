import {Routes , Route } from "react-router-dom"; import '../queries/style.css';
import apptoreicon from '../assets/phone.png';
import {SiteHeader, SiteFooter} from '../parts/index';
import image from '../assets/newposter1.jpg';
import image2 from '../assets/newposter2.jpg'; 
import header1 from '../assets/header1.jpg'; 
import header2 from '../assets/header2.jpg'; 
import header3 from '../assets/header3.jpg'; 
import header4 from '../assets/header4.jpg'; 
import header5 from '../assets/header5.jpg'; 
export default function Foodvilix()
{ 
return (
<main className="container-fluid">
<div className="header">
 <SiteHeader />
</div>
<main className="container-fluid">
  <main className="container-fluid  container-fluid-strom">
    <section className="row">
       <div className="col-4 col-md-4 col-sm-4 col-xs-4">
         <img src={header1} id="getRessolved"  />
       </div>
       <div className="col-4 col-md-4 col-sm-4 col-xs-4">
        
       </div>
       <div className="col-4 col-md-4 col-sm-4 col-xs-4">
         <img src={header3}  id="getRessolved"  />
       </div>
    </section>
   <section className="row">
       <div className="col-4 col-md-4 col-sm-6 col-xs-6 view-l0-e7">
       <h1 className="display-4">foodvilix</h1>
           <br />
                 <p align="justify"> <strong>Download Foodvilix and start ordering your favorite food from 9000+ catlogs. </strong></p>
                 <p align="justify"><strong> The reccommender use series of algorithms to engineer a sales process and boost it. The foodvilix brand classification is monitored and developed by Softvilix Nextware Pvt Ltd. </strong> </p>
                   <p align="justify"><strong>The food from restaurents accross you processed by the sellers executing a sales process.  </strong> </p>
                     <p  align="justify"><strong>You can Order Food from featured food brands or explore our catlogs. The food catlogs from classified brands. </strong></p>
                       <p align="justify"><strong>Particpate in classification list your restaurent start serving food online.</strong></p>
                        <p align="justify"><strong> Not Installed Foodvilix. Start starving! get thundard speed to expect food to you. </strong></p>
                          <p align="justify" className=" btn"> 
                          <ul className="viewstrom">
                            <li><a className="display-4" href="#" role="button"> <img src={apptoreicon} /></a></li>
                            <li><a className="display-4" href="#" role="button"> Download</a></li>
                          </ul>
                          </p>    
                         </div> 
                         <div className="col-4 col-md-4 col-sm-6 col-xs-6"  />
                       <div className="col-4 col-md-4 view-l0-e7" > 
                       <h1 className="display-4">foodvilix</h1>
           <br />
                <p align="justify"> <strong>The matrix of information engine runs on reccomendations. The recommendation are the scripts that powers foodvilix algorithms.</strong></p>
                  <p align="justify"><strong>The foodvilix brand classification is monitored and developed by Softvilix Nextware Pvt Ltd. </strong></p>
                   <p align="justify"><strong>The nomenclature the foodvilix sales process is engineered is with terms as FA (Foodvilix Administration), Restaurents, Deliveryship and Custumers. </strong> </p>
                     <p align="justify"><strong> The foodvilix runs matrices of information processing over systems at Softvilix Homelandtan.</strong></p>
                     <p  align="justify"><strong>Get ready and download Foodvilix Classified App from App Store or get through start Deliveryship</strong></p>
                        <p align="justify"><strong> Now since Foodvilix! or then better not starving! trust us, trust 9000+ brand sellers. </strong></p>
                          <p align="justify" className=" btn"> 
                          <ul className="viewstrom">
                            <li><a className="display-4" href="#" role="button"> <img src={apptoreicon} /></a></li>
                            <li><a className="display-4" href="#" role="button"> Download</a></li>
                          </ul>
                          </p>    
                       </div>
                </section>
                <section className="row container-fluid-strom">
                  <div className="col-4 col-md-4 col-sm-4 col-xs-4 container-fluid-strom"  />
                     <div className="col-4 col-md-4 col-sm-4 col-xs-4 view-l0-e7 container-fluid-strom">
                               <br />
                              <img src={image} id="view-id-container" />
                          </div>
                       <div className="col-2 col-md-2 col-sm-2 col-xs-2" />
                     </section>
                     <br />
                     <br />
                     <br />
                     <section className="row">
                  <div className="col-4 col-md-4 col-sm-4 col-xs-4">
                        <img src={header2}   id="getRessolved"  />
                    </div>
                     <div className="col-6 col-md-6 col-sm-6 col-xs-6 view-l0-e7">
                         <div className="container-fluid"> 
                 <h1 className="display-4">Foodvilix Deliveryship</h1>
                     <p align="justify"><strong> Get instant credits by delivering orders online & get qualified returns gaurenteed. Download Deliveryship App Now. </strong> </p>
                     <p align="justify"><strong> Please request web form provided on poratal for Delivership ID, get set do Deliveryship Download Deliveriship app. </strong> </p>
                      </div>
                       </div>
                       <div className="col-2 col-md-2 col-sm-2 col-xs-2" />
                       <br />
                       <br />
                       <br />
                     </section>
                 
           

<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
  </main>
 </main>
<SiteFooter />
</main> );
}