
import React from 'react';
import We from './about/KnowUs';
import Products from'./about/concerns';
import Jobs from './about/Jobs';
import Profiles from './about/profile';
import {Routes , Route } from "react-router-dom"; 
import Excellence from'./components/Excellence'; 
import Foodvilix from './components/Foodvilix'; 
import Programmerthon from './components/Programmerthon';  
import { NavLink, BrowserRouter } from "react-router-dom"; 
import {Switch } from "react-router-dom"; 
import CustomerPolicy from './policy/custumer-policy';
import IntellectualRights from './policy/intellectual-rights'; 
import Terms from './policy/term'; 
import EARights from './policy/EArights'; 
import FARights from './policy/FArights'; 
import Privacy from './policy/privacy'; 
import Trademarks from './policy/trademark'; 
import Commencements from './policy/commencement'; 
import FoodvilixProduct from './products/foodvilix';
import Extend from './products/extend';
import Softvilix from './products/softvilix';
import _404Error_Page_Not_Found from './components/404';

export default function Page() {

    return (
      <BrowserRouter>
           <Switch>
             <Route exact path="/">
              <Excellence />
            </Route>
           <Route path="/foodvilix">
              <Foodvilix />
          </Route>
          <Route path="/programerthon">
              <Programmerthon />
          </Route>
          <Route path="/custumerpolicy">
              <CustomerPolicy />
          </Route>
          <Route path="/intellectualrights">
              <IntellectualRights />
          </Route>
          <Route path="/terms">
              <Terms />
          </Route>
          <Route path="/EARights">
              <EARights />
          </Route>
          <Route path="/FARights">
              <FARights />
          </Route>
          <Route path="/privacy">
              <Privacy />
          </Route>
          <Route path="/trademarks">
              <Trademarks />
          </Route>
          <Route path="/commencements">
              <Commencements />
          </Route>
          <Route path="/knowus">
              <We />
          </Route>
          <Route path="/products">
              <Products />
          </Route>
          <Route path="/profiles">
              <Profiles />
          </Route>
          <Route path="/fa">
              <FoodvilixProduct />
          </Route>
          <Route path="/ea">
              <Extend />
          </Route>
          <Route path="/ee">
              <Softvilix />
          </Route>
          <Route path="">
              <_404Error_Page_Not_Found />
          </Route>

      </Switch>
  </BrowserRouter>
  );
  }


 



