
import logo from './logo.svg';
 import './App.css'; 
 import './index.css'; 
 import './font.css'; 
 import React, { useState } from "react"; 
 import { NavLink, BrowserRouter } from "react-router-dom"; 
 import Page from './Page'; 
 

 
 export default function App() { 
 return ( 
    <BrowserRouter> 
       <Page />
    </BrowserRouter>
); 
    
 }