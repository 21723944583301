import React, { useState } from "react"; import { NavLink } from "react-router-dom"; import {Routes , Route } from "react-router-dom" ; import { Component } from 'react'; 

export default function SiteHeader() { 
    return( 
 <header className="container-fluid header-component">
   <div className="row">
      <column className="col-6 col-md-6 col-sm-6 col-xs-6"> 
         <h1 className="display-4" id="username"><strong> NEXTVILIX NEXTWARES PRIVATE LIMITED</strong></h1>
      </column>
      <column className="col-6 col-md-6 col-sm-6 col-xs-6">
          <navigation className="col-6 col-md-6 main-menu">
                    <ul>
                        <li>
                            <NavLink className="display-4" activeClassName="is-active" to="/"> Excellence </NavLink>
                        </li>
                        <li>
                            <NavLink className="display-4" activeClassName="is-active" to="/foodvilix"> Products</NavLink>
                        </li>
                        
                           <li>
                            <NavLink className="display-4" activeClassName="is-active" to="/programerthon"> Programmerthon </NavLink>
                         </li>
                        <li>
                             <NavLink className="display-4" activeClassName="is-active" to="/knowus"> KnowUs </NavLink>
                        </li>
                    </ul>
             </navigation>
         </column>
   </div>
</header> ); }