import '../index.css'; import {SiteHeader, SiteFooter} from '../parts/index'; 

export default function Trademarks(){ 
    return (<trademark className="container-fluid">    
         <SiteHeader />
    <container className="container-fluid">

    <div className="policy">
        <ul>
    <li><p align="justify"><b>Trademarks</b> <br /> Trademarks are subject to maintenance on the portal, terms of use, use as reserved content rights, and business terminology used by reserved keywords in trademark registry (IP India) registered under business constitutions. They are subject to be licensed & by their use, and they proceed company's policy of business terms, terminologies, and theories.</p>
     <p align="justify">Nextvilix Nextwares Private Limited is concerned with the use of trademark policy for the following rights licensed & trademarked under the following key policy for the use and administrate with trademarks with context to science and meaning. <b> Licence:</b> The trademark is licensed to the console management for reserved work, and under the use of trademarks licensed to businesses, authorities, or product management registered by the formation of claimer of intellectual Rights and declaration. It is a act. </p>
    </li>
    <li>
    <p align="justify"><b>Usage: </b> Trademark to be use as a content  user privileges to make use of  in use of subject licensed of and all. To use the trademark in official content necessarily make use of the content relevant mentions and does validate its functionality in context. Such content derives product usage and is appropriate. <b>As Image:</b> Trademark as an official media may be have mandate to use watermark, certain acquaintances and rich to exempt such extensibility or mentioned at the bottom of the document. It is need to mention because use of it is restricted. <b>In Image: </b>Trademark resembled in image can have a tag or meta information manipulated on its use of media device should be commented, hastag, mention contributors, mention at social media. It is because it has its conceptual importance.</p>
    </li>
    <li>
    <p align="justify"><b>Content:</b> Copyrighted content is reserved,  it can be manipulated by third parties. Licence of trademark must follow guidelines to use such mentioned right by the content publisher/owner. Content rights are priliminary in distribution and use of such rights is important. <b> Technical:</b> Terminologies undergo the analysis to face the same scenario it was set upped, in exceptional cases and it is been mandate. It is been used by system, it need to be metioned. Its appropreate prototypal remains prototypal. </p>
    </li>
    <li> <p align="justify"> <b>Subject:</b>Trademark subject to be used into subject - in studies, in documentation, in research documents, websites, application and dynamic manipulation by the host. Trademarks used in development and analysis.<b>Limited:</b>Trademark are limited to use of context for purpose of its usage. It is mandate to use and mention trademarks by use of it is been. Limited to the context it is been used in areas need mention in respective concerns.   </p>
   </li>
   <li>
   <p align="justify"> <b>Integrity:</b> Integrity involved in the use of trademark in concern areas of the research and work progress, reserved meaning in cooperation, mentioning it's use do consistent integrity as a key outcome that resolve concepts. <b>Associate:</b> The concerns follow to use of the following trademarks: Nextvilix Nextwares, Foodvilix, Deliveryship, Programmarthon in context to bussiness under Foodvilix Central Recommendation Engine & Foodvilix Matrices. </p>
    </li>
    <li>
    <p align="justify"><b>Significance: </b> Trademark are used under publications, use of research, context, medical, scientific, and respective areas as per use of goods and services, of the authorised works by trademark owners, copyrights, licences. We believe it <b>Specification: </b> Use of trademark as a qualified specification of a software or hardware which resolve the use of internal architecture of product specification and it is necessary to make use of the trademark to be declared. <b>Runtime tests:</b> Debugging is complicated environment and use of the maintenance guidelines to maintain quality and analysis are instructed to define usage of the trademark in publication or release, more than respect they have been in analysis. </p>
    </li>
    </ul>
    </div>
    </container>
    <SiteFooter />
    </trademark>
); }