import linkedinicon from '../assets/linkedin.png';
import instagram from '../assets/instagram.png'; 
import youtube from '../assets/youtube.png'; 
import {SiteHeader, SiteFooter} from '../parts/index';
function We() {
    const startblock = '{';
    const bracketopen = '['; 
    const bracketclose=']'; 
    return (
  <container>
   <main className="header">
      <SiteHeader />
    </main>
    <main className="container-fluid">
    <br />
      <div className="row">
        <div className="col-4 col-md-4 col-sm-4 col-xs-4">
          <p><b>@NEXTWARES</b></p>
          </div>
          <div className="col-1 col-md-1 col-sm-1 col-xs-1"><a href="https://www.linkedin.com/company/nextwares" target="remarkablepatience"><img src={linkedinicon} alt="softvilixsolutions" className="compiled" /></a> </div>
          <div className="col-1 col-md-1 col-sm-1 col-xs-1"><a href="https://www.instagram.com/nextwares/" target="remarkablepatience"><img src={instagram} alt="softvilixsolutions" className="compiled" /></a></div>
          <div className="col-1 col-md-1 col-sm-1 col-xs-1"><a href="https://www.youtube.com/nextwares" target="remarkablepatience"><img src={youtube} alt="softvilixsolutions" className="compiled" /></a></div>   
       </div>
       <p align="justify"><strong><i>in context and meaning: </i></strong>practices and presumptions in resemblance of the quest of techniques that serve better ideas/needs who develope builds to the practices of Software and Algorithms via Software Engineering, Software Architectures and applications.</p>
       <p align="justify">The conception advent disciplined engineering for a sales process, applying constructs of accounts, algorithms. It'd be acquiring rid of technology thinking perspective about learning and learners. Perspect, how learner builds can model clusters, grids, and clouds. </p>
       <p align="justify">We are techhnology researchers who built things on revenue and progressions who run product administration on research executed at Labs at Softvilix Homelandtan, corporate office of the bussiness. We curriculum, built solutions, develope generations of computers and platform, editions. </p>
       <p align="justify">We as a technology researchers who execute building apps for interaction with Computers, technolgy, power and machinary with HCI Theories.<i>Nextvilix Nextwares</i> Curriculum compiles work inspired by a vision to develop a 'learn from systems' and stack introductions in context-driven new generation approaches in studies compiled as HCI. </p>
       <p align="justify">What got nut to shell? What got excellence to believe? What got motivation legacies passon by technologicians? the momentum <i>new-generation of them</i> theory computing, is progressive are research comitting product and revenue.</p>
                  
       <p className="display-profile"><b>INVESTOR PROFILES </b></p>
   <div className="row">
      <div className="col-6 col-md-6 col-sm-6 col-xs-6"> 
             <p><strong>Founder [Deceased]</strong></p>
             <p align="justify">
              Mr. Arjun Mujgule who been Founder, is first formation investor of the Nextvilix Nextwares Private Limited. He been in professional career with business leader Shri. Madhavrao Shinde as an management Secretery. </p>
       </div>
   <div className="col-6 col-md-6 col-sm-6 col-xs-6">
      <p><strong>Co-Founder</strong></p>
      <p align="justify">Mr. Shailendra Mujgule as a investor co-founded and have biggest investment to Nextvilix Nextwares Private Limited. He founded Nextvilix Nextwares Private Limited along with Arjun Mujgule.</p>              
    </div>
  </div>
<h1>CONTRIBUTORS PROFILES</h1>
<br />
<div className="container-fluid">
    <div className="row">
     <div className="col-6 col-md-6 col-sm-6 col-xs-6">
     <p><strong>CEO and Chairman</strong></p>
           <p align="justify">Mr. Satyajeet Shailendra Mujgule is whole time enthusiast biggest estabilisher of software development and product engineering grounds, revenue with Computers/Computing and is designated CEO by the Board, Nextvilix Nextwares Private Limited. He estabilished Nextvilix Nextwares Private Limited along with Ravindra and Krushna.</p>              
              
         <p><strong>Whole Time Director (WTD)</strong></p>
           <p align="justify">Mr. Krushna Babasaheb Jawale is whole time enthusiast biggest estabilisher of Event,mind, market planning and product engineering grounds, markups platforms and is designated WTD by the Board, Nextvilix Nextwares Private Limited. He estabilished Nextvilix Nextwares Private Limited along with Ravindra and Yogesh.</p>              
                  </div>
          <div className="col-6 col-md-6 col-sm-6 col-xs-6">
            <p>
              <strong>CFO</strong></p>
               <p align="justify">Mr. Ravindra Nikam is whole time analyst and biggest contributor to the revenue and revenue thoeries over grounds and is designated Chief Finance Officer (CFO) by the Board, Nextvilix Nextwares Private Limited. He estabilished Nextvilix Nextwares Private Limited along with Satyajeet and Yogesh.</p>             
               
               <p><strong>Policy Writer (PW)</strong></p>
               <p align="justify">Mr. Dinesh Shivaji Thorat is whole time Policy Writer biggest estabilisher of protocol,policy and conclusions and product engineering grounds with Computers/Computing and is designated Policy Writer by the Board, Nextvilix Nextwares Private Limited. He estabilished Nextvilix Nextwares Private Limited along with Ravindra and Yogesh.</p>              
            
                </div>
          </div>
          <div className="row">
          </div>
          </div>
      <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
</main>
 <footer> 
    <SiteFooter />
 </footer>
</container>);
}

export default We; 