import React, { useState } from "react"; 
import {NavLink } from "react-router-dom";
import {Routes , Route } from "react-router-dom";
import '../queries/style.css'; 
import linkedinicon from '../assets/linkedin.png';
import youtube from '../assets/youtube.png'; 
import twitter from '../assets/twitter.png';
import facebookicon from '../assets/facebook.png'; 
import instagram from '../assets/instagram.png'; 
import github from '../assets/github.png'; 
import email from '../assets/emailicon.png';
import profile2 from '../assets/twitterprofile.png'; 
import profile1 from '../assets/linkedinprofile.png'; 
import foodvilixstoreicon from '../assets/phone.png';
import store from '../assets/foodvilix.png'; 
import slack from '../assets/slack.png'; 
import medium from '../assets/medium.png';
import contact from '../assets/whatsapp.png';
import address from '../assets/home.png';
import amazoninicon from '../assets/amazon.png'; 
import icon1 from '../assets/whatsappcontact.png'; 
import icon2 from '../assets/emailicon.png'; 
import youtubeicon from '../assets/youtubeicon1.png';
import linkedconnectus from '../assets/linkedinviewimage1.png';
export default function SiteFooter(){
return ( 
<footer className="footer" id="footer">
  <br />
    <section className="row">
      <div className="col-12 col-md-12 col-sm-12 col-xs-12">
        <div className="row vvvv">
          <div className="col-2 col-md-2 col-sm-2 col-xs-2"> <a href="https://www.youtube.com/@nextwares" target="remarkablepatience"><img src={youtube} alt="softvilixsolutions" className="compiled" /></a> </div>
          <div className="col-2 col-md-2 col-sm-2 col-xs-2"> <a href="https://www.twitter.com/nextwares" target="remarkablepatience"><img src={twitter}alt="softvilixsolutions" className="compiled" /></a> </div>
          <div className="col-2 col-md-2 col-sm-2 col-xs-2"> <a href="https://www.facebook.com/nextwares" target="remarkablepatience"><img src={facebookicon} alt="softvilixsolutions" className="compiled" /></a> </div>
          <div className="col-2 col-md-2 col-sm-2 col-xs-2"> <a href="https://www.instagram.com/nextwares/" target="remarkablepatience"><img src={instagram} alt="softvilixsolutions" className="compiled" /></a> </div>
          <div className="col-2 col-md-2 col-sm-2 col-xs-2"> <a href="https://github.com/nextwares" target="remarkablepatience"><img src={github} alt="softvilixsolutions" className="compiled" /></a> </div>
          <div className="col-1 col-md-1 col-sm-1 col-xs-1"> <a href="https://medium.com/@nextwares" target="remarkablepatience"><img src={medium} alt="softvilixsolutions" className="compiled" /></a> </div>
        </div>
       </div>
   </section>
   <br />
<container className="container-fluid">  
<main className="row col-width-text-void">
                                <span className="col-6 col-md-6 col-sm-12 col-xs-12">
                                     <div className="main-container-title"> <h3 align="justify"><strong>@Nextwares</strong></h3>  </div>
                                          <p align="justify" id='gl-next'> <strong>
                                             Progressive developement in Human Computer Interaction (HCI) Product Base, series of new generation of computer technology web apps, biggest solvers and Learner architectures with algorithms and enhancements with writing routines
                                        </strong>
                                      </p> 
                                   </span> 
                                     <span className="col-6 col-md-6 col-sm-12 col-xs-12">
                                       <p align="justify" id="footer-section-columns"><strong><br /> R/O <br />  Softvilix Homelandtan, Kopargaon-Sangamner Road, Pohegaon bk, Kopargaon, Ahmednagar, Maharashtra, India 423605 </strong></p>
                                   </span>
                              </main>
                    
      <main className="row col-width-text-void">
           <div className="col-6 col-md-6 col-sm-6 col-xs-6">
                      <br />
                    <div className="row">
                     <div className="col-1 col-md-1 col-sm-1 col-xs-1">
                        <NavLink className="col-8v-r0" activeClassName="is-active" to="/foodvilix"> Foodvilix</NavLink>
                     </div>
                     <div className="col-10 col-md-10 col-sm-10 col-xs-10">
                        <p  align="justify"><strong> Fond of food? Buy food online on foodvilix mobile app and discover the best food catlogs online.   </strong> </p> 
                     </div>
                 </div>
            </div>
            <div className="col-6 col-md-6 col-sm-6 col-xs-6">
                      <br />
                      <div className="row">
                         <div className="col-2 col-md-2 col-sm-2 col-xs-2">
                              <p align="justify"> <a className="col-8v-r0" activeclassNameName="is-active" href="https://www.softvilixsolutions.com">  <p id="viewFor-nav"> &#9881; <strong>Solutions</strong> </p> </a></p>
                           </div>
                           <div className="col-10 col-md-10 col-sm-10 col-xs-10">
                           <p  align="justify"><strong> Domain Name Solutions for domain name register, DNS, Email, SSL, Development, Privacy and more.   </strong> </p>
                     </div>
                 </div>
             </div>
            </main>
            <main className="row col-width-text-void ">
               <span className="col-6 col-md-6 col-sm-6 col-xs-6">
                 <div className="row">
                     <div className="col-1 col-md-1 col-sm-1 col-xs-1">            
                        <NavLink className="col-8v-r0" activeclassNameName="is-active" to="/knowus"> <p align="justify"> <strong> AboutUs</strong></p> </NavLink>
                      </div>
                       <div className="col-10 col-md-10 col-sm-10 col-xs-10">
                          <p align="justify"><strong> HCI Product Base Technology Company having progressions in FoodComerse, Computing, Development, Platforms, Network. </strong> </p> 
                        </div>
                     </div>
                 </span> 
                 <span className="col-6 col-md-6 col-sm-6 col-xs-6">                  
                  <div className="row">
                      <div className="col-2 col-md-2 col-sm-2 col-xs-2">
                                   <p align="justify"> 
                                     <a className="col-8v-r0" activeclassNameName="is-active" href="https://www.linkedin.com/company/nextwares">  <p id="viewFor-nav"> &#187; <strong>Nextwares</strong> </p></a></p>          
                                 </div>
                                 <div className="col-10 col-md-10 col-sm-10 col-xs-10">
                                     <p align="justify"> <strong> 
                                           Design, Development, Engineering Wep Apps, learner builts and new longer next of development with Nextwares.</strong></p>
                                   </div>
                               </div>
                          </span>  
                    </main>
  
                     <main className="row col-width-text-void">
                       <div className="col-6 col-md-6 col-sm-6 col-xs-6"> 
                           <p align="justify" ><strong> &#x2706; +91 7666301350  &nbsp; <a href="mailto:alarkbot@softvilixnextwares.com" id="viewwarm"> @Alarkbot</a></strong><strong> &nbsp; &copy; foodvilix </strong>  &nbsp; &nbsp; <strong>Softvilix Solutions &trade;</strong>  &nbsp; <strong>Foodvilix &trade;</strong>&nbsp; <strong>Nextvilix Nextwares &trade;</strong></p> 
                       </div> 
                     </main>
                       <main className="row col-width-text-void">
                          <div className="col-6 col-md-6 col-sm-6 col-xs-6" />
                             <div className="col-6 col-md-6 col-sm-6 col-xs-6">
                               <div className="row">
                                   <div className="col-2 col-md-2 col-sm-2 col-xs-2">
                                   <p align="justify"> 
                                     <a className="col-8v-r0" activeclassNameName="is-active" href="https://www.linkedin.com/company/nextwares">  <p> <img src={linkedinicon} alt="softvilixsolutions" className="compiled" /> LinkedIn </p> </a></p>          
                                      </div>
                                      <div className="col-10 col-md-10 col-sm-10 col-xs-10">
                                       <p align="justify"> <strong> Nextvilix Nextwares PLC initiative of the Nextwares: Softvilix Nextwares Private Limited is Cost Audited Technology Company. The sales process engineered by recommendations identified by algorithms of Product Platform Foodvilix Softvilix Solutions' registered name Softvilix Nextwares Private Limited (Private Company). India's HCI (Human Computer Interaction) Product base is here. Company is known for PRM (Product Revenue Model).  Softvilix Nextwares Pvt Ltd is a chain of excellence.</strong></p>
                                   </div>
                               </div>
                          </div>
                       </main>
                        <main className="row col-width-text-void">
                               <div className="col-6 col-md-6 col-sm-6 col-xs-6"  />
                               <div className="col-6 col-md-6 col-sm-6 col-xs-6">
                           <div className="row">
                              <div className="col-2 col-md-2 col-sm-2 col-xs-2">
                               <p align="justify"><a className="col-8v-r0" href="https://medium.com/@nextwares" target="remarkablepatience"><img src={medium} alt="softvilixsolutions" className="compiled" /> Mediumblogs</a></p>
                                 </div>
                                   <div className="col-10 col-md-10 col-sm-10 col-xs-10">
                                     <p align="justify"><strong>
                                         Nextvilix Content and blogs are recently updated, published on medium. Feel enthusistic to discover them first. 
                                    </strong>
                                  </p>
                                 </div>
                               </div>
                              </div>
                         </main>
                          <main className="row col-width-text-void">
                                   <span className="col-6 col-md-6 col-sm-9 col-xs-9"> <p><strong> © NEXTVILIX NEXTWARES PRIVATE LIMITED | CIN-U72900MH2022PTC378874 | SOFTVILIX NEXTWARES &#8482; </strong></p></span>
                                   <span className="col-6 col-md-6 col-sm-6 col-xs-6">
                                   <div className="bottom-menu">
                               <strong>
                             <ul>
                             <li>
                             <NavLink className="navbar-item" activeclassNameName="is-active" to="/custumerpolicy"> <strong>Custumer Policy</strong> </NavLink>
                            </li>
                            <li>
                            <NavLink className="navbar-item" activeclassName="is-active" to="/intellectualrights"> <strong>Intellectual-Rights</strong> </NavLink>
                            </li>
                            <li>
                            <NavLink className="navbar-item" activeclassName="is-active" to="/terms"> <strong>Terms </strong> </NavLink>
                           </li>
                           <li>
                           <NavLink className="navbar-item" activeclassName="is-active" to="/EARights"> <strong>EA-Rights</strong> </NavLink>
                           </li>
                           <li>
                           <NavLink classNameName="navbar-item" activeclassName="is-active" to="/FARights"> <strong>FA-Rights </strong> </NavLink>
                           </li>
                          <li>
                          <NavLink className="navbar-item" activeclassName="is-active" to="/privacy"> <strong> Privacy </strong> </NavLink>
                          </li>
                          <li>
                         <NavLink className="navbar-item" activeclassName="is-active" to="/trademarks"> <strong>Trademarks</strong> </NavLink>
                         </li>
                         <li>
                         <a className="navbar-item" activeclassName="is-active" href="https://www.foodvilix.com"> <strong>Foodvilix.Com</strong> </a>
                         </li>
                         <li>
                         <a className="navbar-item" activeclassName="is-active" href="https://www.softvilixsolutions.com"> <strong>Solutions</strong> </a>
                        </li>
                    </ul>
                  </strong>
               </div>
           </span>
       </main>
</container>
</footer> 
); 
}