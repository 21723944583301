import headertechicon from '../assets/tech.png'; 
import featureimage from '../assets/tech.png'; 
import illustration1 from '../assets/illustration1.png';
import illustration2 from '../assets/Illustration2.png';
import illustration3 from '../assets/illustarion3.png';
import illustration5 from '../assets/illustration5.png';
import illustration6 from '../assets/illustration6.png';
import headerimage from '../assets/preview-page0.jpg';
import React, { useState, State } from "react";
import dev from '../assets/devlopment.jpg';
import {SiteHeader, SiteFooter} from '../parts/index'; 

export default function Excellence() {
const [postContent, setPostContent] = useState(''); 
const getmewhatttypedsyntaxis = () => {
    let count = 1; 
    if(postContent === '' && count === 1) {
        document.getElementById("returned").innerHTML = 'We proccesed your empty message. Please try better time!';
        count = count + 1;
     }
     else
     {
      document.getElementById("returned").innerHTML = 'Thank you! your message is compiled! We greet you for this day! Have a wonderfull day! We will get it read sooner.';
      count = count + 1;
     }
      
}

const block = "=>";
const arrow = "=>";
const plane = "]=<";
const content= "#";
const codes = "!--->";

return( 
<main className="container-fluid">
  <header className="header-component">
     <SiteHeader />
     </header>
           <div className="vm">
                <span className="row">
                   <div className="col-6 col-md-6 col-sm-12 col-xs-12"><p id="builtformulations"><h1 className="display-4" id="builts"><strong>Nextvilix <br />Nextwares<br />.COM<br />Brand Productbase that suits better possible<br /> Now <br/> Develop <br /> Build <br /> Register with Domain Names?<br /></strong></h1><p id="span-content" align="justify"><strong><br /> Quickly register your Domain Name with SoftvilixSolutions.Com. We provide Domain Name Solutions, SSL, Payments Solutions and more.<br /> </strong></p></p></div>
                    <div className="col-6 col-md-6 col-sm-12 col-xs-12"><p id="builtfoundations"><h1 className="display-4" id="builts"><strong>EMBRASING ENERGETIC PROFESSIONAL LEADING INDUSTRY PROFESSIONALS IN WEB APPS</strong></h1> <h2 id="span-content"align="justify"> <strong> <br /> act new domain control new better process in web app development. Focus the new enhancement, design, build solutions on web not like better thought in right head, but exactly.</strong> </h2></p></div>
                   </span>
              </div>
               <main className="container-fluid wrapper col-width-text-void">
                   <br />
                      <br />
                        <br />
                          <br />
                            <span className="row">
                                <div className="col-4 col-md-4 col-sm-4 col-xs-4"><p id="builtformulations"><h1 className="display-4" id="builts"><strong>Curriculum, <br />Solutions, <br />InfraArchitectures </strong></h1></p></div>
                                   <div className="col-7 col-md-7 col-sm-7 col-xs-7"><h2 id="span-content" align="justify"><p><strong><br /><br /> Curriculum Data Science, Data Structures and Algorithms, Design and Analysis of the Programming Languages or descrete computer technology concepts. The most common and advance studies bringing curriculum to analysis.The extraordinary work resemble the best of the usecase to formulate the better principles and development systems, builts sollutions those are existed in ecosystem and are served through engineering solve the better need of the quest. Powers Foodvilix Platforms: The, Recommender to the Foodlover and better place to develope learner code. Develop work rooms, increase your sales. Create a better usage of the sales process develop on food get quality food or built your own exception making better code better idea. Get to know foodvilix. </strong> </p></h2></div>
                               </span>
                              <span className="row">
                                <div className="col-4 col-md-4 col-sm-4 col-xs-4"><p id="builtfoundations"><h1 className="display-4" id="builts"><strong>Systems and Frameworks</strong></h1></p></div>
                                    <div className="col-7 col-md-7 col-sm-7 col-xs-7"><h2 id="span-content" align="justify"><p><strong> approaching context in recommedation systems built on retrivals, engineering the foodcomerse sales process by context and information. New better workplace to do system and that works get better better needs smarter on the best one to get it through. The programming interface develope withstanding the better usecase of the fundamentals. The development which context the studies and utilization of it to the most common environment at cost to model problems that does make certain standing algorithms in the ecosystems. The most better one do the one is written for or such analysis can be breaked into instructions that work in real place who propose the use of the algorithms to stand revenue systems, all that cost in purpose or is the problem solvers great interface that work well.</strong> </p> </h2></div>
                              </span>
                            <br />
                          <br />
                     </main>
  <div className="header-section-coluumn-view-align">
    <section className="container-fluid">
        <section className="row new-ex-label whodoesthemudatnut"> 
           <div className="col-2 col-md-2 col-sm-2 col-xs-2 ">
              <div className="row">
                 <div className="col-2  whodoesthemudatnut">
                   <div className="col-2  whodoesthemudatnut">
                     <div className="col-2  wrapper whodoesthemudatnut">
                       <h1 className="display-4 span-max-colum-main" id="stand-aload-longer-void"> What builds on Next?</h1>
                        <h1 className="display-4 span-max-colum-main" id="stand-aload-longer-void">IS The Longer Next Of technology that Follows Softvilix HCI BOOK</h1>
                         <div className="col-2 col-md-2 col-sm-2 col-xs-2  whodoesthemudatnut">
                            <div className="row">
                                 <div className="col-2  whodoesthemudatnut">
                                   <div className="col-2  whodoesthemudatnut">
                                         <div className="col-2 whodoesthemudatnut">
                                      </div>
                                  </div>
                              </div>
                           </div>
                       </div>
                     </div>
                   </div>
                 </div>
              </div>
             </div>
               <section className="col-2 col-md-2 col-sm-2 col-xs-2" />
                  <section className="col-7 col-md-7 col-sm-7 col-xs-7 know-relym">
                    <p id="span-view-r8"><strong><p id="stable-id span-content" align="justify">From our publications:The HCI With Product & Development for Web Architectures? Web methods, Context driven methodologies? Algorithms, Processing, learning, Digital Food-Commerse, Commersive Content Networks, Frameworks, Guides, Progressive Web models.</p>
                    <p align="justify">The Progressive forward compatabilities introduced us to excellent progressed technologicians? Who exceptionally been transformers of the masterpieces [Computer, Internet, Web, Wireless, Progressions, Technologies], by Computer Fabricators/Computer Producers/Computer Manufacturers, Engineers, Ethusiasts, Programmers, Influencers. </p> 
                    <p align="justify">Commersively the one who wonder upon beginning there is a host and we need to write programs to it? Get remote content rendered? The progressed remoteness, network, or application in generation of the computing.The legacy of the development builds to the generations of computing head pointless analysis in the essential process. The believer's miracle about this key-analysis is it's been evolving. </p>
                    <p align="justify">Who defines Human Interaction with Computer by may be many translations,  representing types, or data types pillars. New comprehensive data types containers based on typal logic. This Forefront Simplicity in getting the correct meaning of what technology and industries proceed in defining builts to the systems, evolve progressive applications. The Progressive compatabilities come under niche, create the niche that generation used as the knowledge compiled as studies and such technology influences are practiced. </p>
                    <p align="justify">The Human interactions did use graphical elements in the concept to simplify systems/communication and not interfaces/theory. Computer studies engineered progression to build next of the progression and make praticle extentions. The Knowledgable interfacing which build them and based on the usage that comes to the place they were come to the point they were built. Languages were mean't to excellent communication. It interface true meaning to use systems for communications. </p>
                    <p align="justify">They head such visual systems to recognise human involvement in computing:solving problems, solving logics, compiling work, represent appropriate importance to commit work goals and make harder practices come true. </p>
                    <p align="justify">All visual factors increase communication with master planned resources with more clearity, taking on clear opportunity to define how important human role in HCI is? Through all point of actions human tend to use resources, study requirements and make environmental plan and enhance pratice that influence use of systems. Progressive systems were convinient to challange theories as like this.</p>
                    <p align="justify">The progressive in develelopment and nextwares in research of the new better terchnology that powers the new programming paradigm, rethink the engineering to classify softwares built apps on the new contagious innovations, the better technology.</p>    
                    </strong>
                    </p>
                </section> 
            </section>
     </section>  
  </div>
  <div className="io">
              <div className="container-fluid">
                <div className="row">                  
                   <div className="col-4 col-md-4 col-sm-4 col-xs-4"> 
                     <img src={illustration5} id="fluid"/>
                   </div>
                  
                   <div className="col-7 col-md-7 col-sm-7 col-xs-7 new-ver-0-r9"> 
                      <br />
                      <h2 id="span-content span-view-r8 ">
                       <strong>
                      <p align="justify">The programming contest hosted on event occasions of the Financial Year of the company, all that better thing does not get in happening in the most working stances or does inherit more properties that do make decision to do better things set right along, code and drive the fastest programming in precedance of picking right meaning. </p>
                      <p align="justify">What use super-set characteristics of the environment, implement progressive methods, methodologies interpret optimum specificity of the logics and problem solving, does'nt stop adopting in translations. The interpretations made do what they proposed to work in all grounds as asked.</p>    
                      <p align="justify" >Foodvilix reccomendations implements Progressions. Progressions are develelopment operations developed and administrated for driven development process and enhancing it by web, mobile, desktop frameworks. Data matrix implements Progressions. Progressions derrive data and development operations to process driven data for maintainable process and enhancing it by engineering, routines, and product development.</p>
                      <p align="justify">The New '#context' learner's  usage of code and logic can be found in documentation on usage and usability. The context of studies which interpret translations of it to optimum correctness in context to use them for once and need not to be repeated instances. Programmers go through instance of code and analysis throughout learning.</p>
                      <p align="justify"> This talk about Logics and code we learn't through, this inspires us to introduce the event of the year hearted to professionals who have enthusiaum in professions. We introduce a curricullum to the championship of the prestigious programmers, professionals. The participants that code along in championship from Nashik, Ahmednagar and Shrirampur.</p>
                      <p align="justify"> We call it, prestigious programmerthon. The event we host each year, talk about techniques and introductions initiated by you and we term those are invented by you in context of your analysis and theory. We believe each year has to be each theory.</p>
                      <p align="justify"> Which help our talent analysis, implement theory on new learning approaches, new things, new programming based on potential, margin and previledges. New ways to deal it, foreforward industry talent and programmerthonion certified by the Softvilix Solutions.</p>
                      <p align="justify"><i><b>Programmerthon</b></i> is declared to be held! Let's come forward and code. Stare with confidence to face the event of the year with all new ones who beleive code. New learners in each year with bigger challenges, the most intelligent code examples. We won't retain programmers codebases. We deliver langauge translation. </p>
                      <p align="justify" >Former practices context study of information strategies and algorithms in use to build systems, build networks, build professionalism. The recommendation context and the matrix of the information engine [FA Central] power algorithms of Foodvilix.</p>
                      <p align="justify" > Now Foodvilix product division acts as dedicated administration committed to needs, Non-Food Revenue. Foodvilix uses a FA Central Engine based on the matrix of analytical information and runs algorithms in context to understand feeds, allocation, path, rank, and digital commerce.</p>
                   </strong> 
                   </h2>
                   </div>
                   <section className="col-2 col-md-2 col-sm-2 col-xs-2" />
                 <div>
              </div>
           </div> 
        </div>
    </div>
          <section className="col-view-vrx-z00" >
                 <img src={dev} className="new-balance-strom" />
                 <h1 className="display-4 betterSetForwardNone"></h1>  
                 <p align="justify" id="col-z0y-r1" ><strong>Computations and translations in Human Interaction with Computer with native types use of the object, methods, hooks, Object strings, developed to typals, types, better instances of classes as Methods, Objects, Block Methods, Repetitive Logics, Constucts, Tables, Data Structures to New Context brought into process and process frameworks. Develop Logic, Enable Object Oriented, Featured Programming, Principles for outstanding thinking built-in for making new construct of programming languages or ways build on translations. 'HCI' defines the use of entities that can be calculated by humans, and are represented as a machine. The generic machine-human ever introduced built on calculations of the human excellent thinking invent and adopt theories. Languages meant', influence to build logical understanding on serval theories of computations that evaluate system logic build on a new level in parsing and dynamic control in establishing optimum grammar that can be processed or rejected. The optimum programming is made to compile human problems in the theory of HCI, using the set matrix of information that humans want to process or manipulate. The generic machine and the performance machine kind relevant context they evaluate by their every principle, process. Use active terms, make all the most of the performance systems, more and more built on the performance machines capable of extending human ways of solving problems. HCI defines all primitive typal machines that do it safely, precautiously, harmlessly is valid human matrix to build systems and we motive this context as our term in HCI to built theories that can really help learners to extend problem solving to the most hearted meaning in the context of manufacturing computers or engineering its applications through mathamatics, environmental programming and science.</strong></p>
                 </section>
                 <br />
                 <div className="container-fluid">
                         <div className="container-fluid">   
                            <section className="container-fluid wrapper-screen-column new-content-wrapper">
                               <div className="row">
                                   <div className="col-2 col-md-2 col-sm-2"> 
                                      <p className="asyncranous-constant"><a href="https://discord.gg/thMazqRg4E">
                                        Curriculum <b></b></a>
                                      </p>
                                  </div>
                                 <br />
                                 <div className="col-8 col-md-8 col-sm-8  wrapper-screen-column new-content-wrapper">
                                    <p align="justify" className='lead'> Let Us Learn Code! evolve the best of what you can, what can help you. What can help build real life architectures with code, learn the learners. Become next generation Programmerthon champion who evaluates only code and Data Structures, preparation is to be made and it was on your marks. <br /> </p>
                                 </div>
                               </div>
                       </section>
                       </div>
                    </div>
                    <br />
                 <main className="container-fluid">
                    <section className="row">
                       <column className="col-7 col-md-7 col-sm-12 col-xs-12">
                           <div className="row">
                               <div className="col-5 col-md-5 col-sm-5 col-xs-5">
                               <div className="col-4 col-md-4 col-sm-4 col-xs-4"></div> 
                               <div className="col-4 col-md-4 col-sm-4 col-xs-4">
                               <section className="container-fluid">
                                               </section> 
                                              </div>
                                              <div className="col-4 col-md-4 col-sm-4 col-xs-4">
                                             <section className="container-fluid">
                                          <div className="wrapper-screen-column">
                                        <h3> [walkers [.()] ] set [class, type retrieve.key.mapto()].to.worksystem -{arrow} init[] = true, throws(_);</h3>
                                       </div>
                                     </section> 
                               </div> <br />
                               <br />
                                   <section className="container-fluid">
                                       <div className="wrapper-screen-column">
                                         <h3> [walkers [.()] ] set [class, type retrieve.key.mapto()].to.worksystem -{arrow} init[] = true, throws(_);</h3>
                                      </div>
                                  </section> 
                                  <div className="col-4 col-md-4 col-sm-4 col-xs-4"></div> <div className="col-4 col-md-4 col-sm-4 col-xs-4"></div> <div className="col-4 col-md-4 col-sm-4 col-xs-4"></div> <div className="col-4 col-md-4 col-sm-4 col-xs-4"></div>
                                  <div className="row">


                                  <div className="col-4 col-md-4 col-sm-4 col-xs-4"></div>
                              
                                  <div className="col-5 col-md-5 col-sm-5 col-xs-5">
                                  <div className="row">
                                  <div className="col-4 col-md-4 col-sm-4 col-xs-4"></div>
                                  <div className="col-4 col-md-4 col-sm-4 col-xs-4">
                                  <section className="container-fluid">
                                     <div className="wrapper-screen-column">
                                              <h3> [walkers [.()] ] set [class, type retrieve.key.mapto()].to.worksystem -{arrow} init[] = true, throws(_);</h3>
                                           </div>
                                       </section> 
                                    </div>
                                    <div className="col-4 col-md-4 col-sm-4 col-xs-4"></div>
                                    <div className="col-4 col-md-4 col-sm-4 col-xs-4">
                                
                                     <div className="col-4 col-md-4 col-sm-4 col-xs-4"></div>
                                    </div>
                                    </div>
                                  </div>
                                  </div>
                                  <div className="col-4 col-md-4 col-sm-4 col-xs-4"></div>
                                <section className="container-fluid">
                             <div className="wrapper-screen-column">
                           <h3> [walkers [.()] ] set [class, type retrieve.key.mapto()].to.worksystem -{arrow} init[] = true, throws(_);</h3>
                        </div>
                   </section>  
                   <br />
                   <br />
                   <br />
           <div className="col-6 col-md-6 col-sm-6 col-xs-6">
              <section className="container-fluid">
                 <div className="wrapper-screen-column">
                   <h3> [walkers [.()] ] set [class, type retrieve.key.mapto()].to.worksystem -{arrow} init[] = true, throws(_);</h3>
                 </div>
            </section> 
          </div>
         </div>
         
       </div>
  </column>
<column className="col-5 col-md-5 col-sm-12 col-xs-12 whodoesbetterhtmlfrv">
   <div className="row">
            <div className="col-6 col-md-6 col-sm-6 col-xs-6">
                <h1 className="sync">function initFA{"((i) => this)"}{"{"} </h1>
                  </div>
                 </div>
              <div className="row">
                  <div className="col-6 col-md-6 col-sm-6 col-xs-6">
                      <h1  className="sync">while{'('} i eachto() {'<' }5 {'{'} </h1>
                      <h1  className="sync">const throws = {'()'} = {'>'} walker [()] setState('started')</h1>
                       <h1 className="sync">return {"("} {"<"}div{">"} {"<h1> <b>"}</h1>
                         </div>
                       </div>
                       <div className="row">
                          <div className="col-6 col-md-6 col-sm-6 col-xs-6">
                             <h1 className="sync">{"<"}p{">"}FA Say's Hello{"</"}p{">)"}</h1>
                          </div>
                       </div>
                       <div className="row">
                         <div className="col-6 col-md-6 col-sm-6 col-xs-6">
                       <h1 className="sync">{";|"}</h1>
                   </div>               
                </div> 
            </column>
      </section>
  </main>
<section className="container-fluid crafter">
            <container className="row">
              <main className="col-6 col-md-6 col-sm-6 col-xs-6"> 
                    <section className="container-fluid">
                         <div className="wrapper-screen-column">
                             <h3> [walkers [.()] ] set [class, type retrieve.key.mapto()].to.worksystem -{arrow} init[] = true, throws(_);</h3>
                           </div>
                     </section> 
                     <section className="container-fluid">
                         <div className="wrapper-screen-column">
                           <h3> [walkers [.()] ] set [class, type retrieve.key.mapto()].to.worksystem -{arrow} init[] = true, throws(_);</h3>
                        </div>
                  </section>
              </main>
      </container>
  </section> 

   <br />
<footer>
  <SiteFooter />
</footer>
</main>
 );
}
